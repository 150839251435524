/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/home.css";

import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import { Ring } from "@uiball/loaders";
import {
  Card,
  CardBody,
} from "@/shared/components/Card";
import Carousel from "@/shared/components/carousel/CarouselMultiply";

import { colorAccent, colorAdditional, colorBackground } from "@/utils/palette";
import { right, left, marginRight } from "@/utils/directions";

import {
  getRecentHistory,
  gettotalAccounts,
  gettotalBalance,
  getotcMarketplaceDetails,
  getNews,
  getstoreDetails,
  getPartner,
} from "../../../utils/services/user.service";
import "../style/Rating.css";
import "../style/storedetail.css";
import "../style/storedetailuser.css";
import "../style/homecard.css";
import "./customStyle.css";
import HomeCard from "./HomeCard";
import NewDetailModal from "./NewsDetailModal";
import Logo from "../../../utils/img/custodiy.png";
import numeral from "numeral";
import PartnerGrid from "./PartnerGrid";

export default function NewHomePage() {
  const [productData, setProductData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [getPartnerData, setPartnerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(productData.length / 10);
  const [isLoading, setIsLoading] = useState(false);

  const [slidesToShowSize, setSlidesToShowSize] = useState(6);
  const [sessionId, setSessionId] = useState(0);

  const [gettotalAccountsVal, settotalAccountsVal] = useState("");
  const [gettotalBalanceVal, settotalBalanceVal] = useState(null);

  const [getMarketplaceData, setMarketplaceData] = useState([]);
  const [getStoreDetail, setStoreDetail] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [show, setShow] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("user"));

  // Define a function to remove duplicate colors
  function removeDuplicateColors(product) {
    const seenColors = new Set();
    // eslint-disable-next-line no-param-reassign
    product.product_sizes_quantity = product.product_sizes_quantity.filter(
      (item) => {
        if (!seenColors.has(item.color)) {
          seenColors.add(item.color);
          return true;
        }
        return false;
      }
    );
  }
  const fetchtotalAccounts = async () => {
    try {
      const response = await gettotalAccounts();
      if (response?.data) {
        const Data = response?.data;

        settotalAccountsVal(Data);
      } else {
        console.error("Error fetching getBTC:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching getBTC:", error);
    }
  };
  const fetchtotalBalance = async () => {
    try {
      const response = await gettotalBalance();

      settotalBalanceVal(response.data.result.totalBalance);
    } catch (error) {
      console.error("Error fetching gettotalBalance:", error);
    }
  };
  const fetchNewData = async () => {
    setIsLoading(true);
    try {
      const response = await getNews();

      if (response?.data) {
        setNewsData(response?.data?.data);
        setIsLoading(false);
      } else {
        console.error("Error fetching gettotalBalance:", response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching gettotalBalance:", error);
    }
  };
  const fetchPartnerData = async () => {
    setIsLoading(true);

    try {
      const response = await getPartner();
      if (response?.data) {
        setPartnerData(response?.data?.data);
        setIsLoading(false);
      } else {
        console.error("Error fetching gettotalBalance:", response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching gettotalBalance:", error);
    }
  };
  const fetchOtchMarketplace = async () => {
    try {
      const response = await getotcMarketplaceDetails();
      setMarketplaceData(response?.data);
    } catch (error) {
      console.error("Error fetching getBTC:", error);
    }
  };
  const fetchStoreDetail = async () => {
    try {
      const response = await getstoreDetails();
      setStoreDetail(response?.data);
    } catch (error) {
      console.error("Error fetching getBTC:", error);
    }
  };

  // Iterate through each product and remove duplicate colors
  if (productData.length > 0) {
    productData.forEach((product) => {
      removeDuplicateColors(product);
    });
  }

  const fetchRecentHistory = async () => {
    try {
      const requestData = {
        session_id: localStorage.getItem("storeSessioId"),
      };
      const response = await getRecentHistory(requestData);

      if (response?.data.status) {
        // console.log('getRecentHistory Response Data : ', response?.data);
        setRecentData(response?.data.recentProducts);
      } else {
        console.error("No category data received");
      }
    } catch (error) {
      console.error("Error fetching getRecentHistory:", error);
    }
  };

  useEffect(() => {
    fetchRecentHistory(); // Load initial data
  }, [currentPage]);

  useEffect(() => {
    if (localStorage.getItem("storeSessioId") === null) {
      const sessionIdValue = Math.random().toString(36).substring(2, 15);
      setSessionId(sessionIdValue);
      localStorage.setItem("storeSessioId", sessionIdValue);
    }

    const intervalId = setInterval(() => {
      if (window.innerWidth < 600) {
        setSlidesToShowSize(3);
      } else {
        setSlidesToShowSize(6);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  useEffect(() => {
    fetchtotalAccounts();
    fetchtotalBalance();
    fetchOtchMarketplace();
    fetchStoreDetail();
    fetchNewData();
    fetchPartnerData();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cryptorank.io/widget/marquee.js";
    script.async = true;
    script.defer = true;

    const widgetContainer = document.getElementById("cr-widget-marquee");
    if (widgetContainer) {
      widgetContainer.appendChild(script);

      return () => {
        widgetContainer.innerHTML = "";
      };
    }
  }, []);

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="d-flex flex-column w-100 align-items-center justify-content-center overflow-hidden  ">
        {/* widget section  */}
        <div
          id="cr-widget-marquee"
          data-coins="bitcoin,ethereum,ripple,custodiy,wen,bnb,uniswap,solana,matic-network,chainlink"
          data-theme="light"
          data-show-symbol="true"
          data-show-icon="true"
          data-show-period-change="true"
          data-period-change="24H"
          data-api-url="https://api.cryptorank.io/v0"
          style={{
            fontFamily: "Oxanium",
            border: "transperent",
          }}
          className="d-flex overflow-hidden "
        >
          <a href="https://cryptorank.io">
            <object data={<Ring />} width="30" height="30">
              Your browser does not support SVGs.
            </object>
          </a>
        </div>

        <div className="d-flex flex-column w-100 align-items-center justify-content-center py-3 overflow-hidden  ">
          <div className="d-flex flex-column text-center  align-items-center justify-content-center gap-4">
            <h1 className="d-flex wight">Custodiy is a Modular Platform</h1>
            <div className="d-flex w-75 text-center ">
              <h5 className="d-flex text-center">
                Custodiy is a modular platform designed to empower individuals
                and businesses alike. Whether you're an entrepreneur looking to
                establish your own marketplace, a seller seeking to expand your
                reach, or an organization aiming to facilitate over-the-counter
                transactions and fundraising, Custodiy provides the tools and
                support you need. Our platform offers seamless solutions for
                storing and managing important documents, ensuring security and
                accessibility. With Custodiy, unlock the potential to thrive in
                today's dynamic market landscape.
              </h5>
            </div>
            <div className="d-flex gap-2 ">
              <div className="d-flex flex-column  align-items-center justify-content-center  gap-4 ">
                <img
                  style={{ width: "30%", height: "auto" }}
                  className="d-none d-md-block"
                  src={Logo}
                  alt="logo"
                />
                <img
                  style={{ width: "60%", height: "auto" }}
                  className="d-md-none d-block"
                  src={Logo || ""}
                  alt="logo"
                />
                {!userInfo?.data?.user && (
                  <Link to={"/login"}>
                    <FormButton variant="outline-primary" type="submit">
                      Log in
                    </FormButton>
                  </Link>
                )}
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-2">
              <HomeCard
                title={"Total User"}
                value={gettotalAccountsVal?.result?.totalUsers || 0}
              />
              <HomeCard
                title={"Total Amount"}
                value={` ${"$ "} ${numeral(gettotalBalanceVal || 0)
                  .format("0a")
                  .toUpperCase()}`}
              />
              <HomeCard
                title={"Total Marketplace"}
                value={getStoreDetail?.totalStore || 0}
              />
              <HomeCard
                title={"Total OTC"}
                value={getMarketplaceData?.totalOtcMarketPlace || 0}
              />
            </div>
          </div>
        </div>
      </div>
      {newsData.length > 0 && (
        <div className="col-lg-12 col-md-12 col-sm-12   text-center">
          <Card className="">
            <CardBody>
              <Carousel height={false}>
                {newsData?.map((item) => (
                  <div key={item.id} className=" ">
                    <div className="square-img-wrapper  ">
                      <img
                        src={item.images[0]}
                        alt={`slide_${item.images[0]}`}
                        className="card-img-top square-img"
                        onClick={() => {
                          setModalData(item);
                          setShow(true);
                        }}
                      />
                    </div>
                    {/* 
                  <h5
                    style={{ textTransform: "capitalize" }}
                    className="card-title producttitle mt-2 mb-0"
                  >
                    {item.title}
                  </h5>

                  <p className="card-title productPrice mb-0">
                    {item.description}
                  </p> */}
                  </div>
                ))}
              </Carousel>
            </CardBody>
          </Card>

          {show && (
            <NewDetailModal
              show={show}
              hide={() => setShow(false)}
              data={modalData}
            />
          )}
        </div>
      )}

      {getPartnerData.length > 0  && <div className="d-flex flex-column justify-content-center  align-items-center py-2  ">
        <h3 className="mt-4">Partners</h3>
        <PartnerGrid getPartnerData={getPartnerData} />
      </div>}

      <div className="d-flex gap-3 justify-content-center align-items-center pb-4">
        <Link to={"/privacy-notice"}>
          <p>Privacy Notice</p>
        </Link>

        <div className="vr text-white"></div>
        <Link to={"/cookie-policy"}>
          <p>Cookie Policy</p>
        </Link>
      </div>
    </>
  );
}

// region STYLES

const ProductItemsWrap = styled.div`
  overflow: hidden;
  width: 100%;
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 20px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 2px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
    font-size: 20px;
  }
`;

const ProductItemsList = styled.div`
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
`;

const ProductCard = styled(Card)`
  padding: 0;
  width: 25%;
  background: red;

  @media screen and (max-width: 1500px) {
    width: 25%;
    background: red;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    background: red;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    background: red;
  }
`;

const ProductItem = styled(CardBody)`
  margin-bottom: 30px;
  height: 440px;
  ${marginRight}: 30px;
  position: relative;
  background-color: ${colorBackground};
`;

const ProductItemImageWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  overflow: hidden;
`;

const ProductItemImage = styled.img`
  height: 100%;
  width: auto;
`;

const ProductItemInfo = styled.div`
  text-align: ${left};
  position: relative;
  width: calc(100% - 90px);
`;

const ProductItemTitle = styled.h4`
  font-weight: 500;
  transition: all 0.3s;
`;

const ProductItemLink = styled(Link)`
  padding: 40px 30px;
  display: block;

  &:hover {
    text-decoration: none;

    ${ProductItemTitle} {
      color: ${colorAccent};
    }
  }
`;

const ProductItemDescription = styled.p`
  margin: 0;
  color: ${colorAdditional};
  line-height: 17px;
`;

const ProductItemPrice = styled.h1`
  position: absolute;
  top: 0;
  ${right}: -90px;
  line-height: 36px;
`;

const ProductItemOldPrice = styled.p`
  position: absolute;
  ${right}: -90px;
  top: 36px;
  line-height: 28px;
  color: ${colorAdditional};
  margin: 0;
  text-decoration: line-through;
`;

const ProductItemColor = styled.span`
  height: 15px;
  width: 15px;
  ${marginRight}: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
`;

const ProductItemLabel = styled.img`
  position: absolute;
  top: -10px;
  ${right}: 5px;
  width: 65px;
`;
