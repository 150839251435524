import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import {
  getUserTopFiveBalance,
  getUserTopFiveOTCBalance,
  getUserTopFiveStrigaBalance,
  getUserTopFiveOnchainBalance,
  getUserInfo,
} from "../../../../utils/services/user.service";
import numeral from "numeral";
import DashboardCoins from "./DashboardCoins";
import { Ring } from "@uiball/loaders";
import styled from "styled-components";
import { useChainContext } from "../../../context/context";
import TotalProfit from "../TotalBalanceGraph";
import { optionColors1, optionColors2, optionColors3, optionColors4 } from "../../../../utils/constants/contants";
import TotalGraphValue from "../TotalGraphValue";
import TotalBalanceValue from "../TotalBalanceValue";
import TotalBalanceValueMobile from "../TotalBalanceValueMobile";

const Top5Coins = ({ dir }) => {
  const [StrigaTable, setStrigaTable] = useState([]);

  const [upcomingCoins, setUpcomingCoins] = useState([]);
  const [OTCCoins, setOTCCoins] = useState([]);
  const [userCoins, setUserCoins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setRefreshInternalBalance, refreshInternalBalace } = useChainContext()


  const fetchStrigaCoins = async () => {
    setIsLoading(true);
    try {
      const res = await getUserTopFiveStrigaBalance();
      setStrigaTable(res?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching Striga coins:", error);
    }
  };

  const fetchUpcomingCoins = async () => {
    setIsLoading(true);

    try {
      const res = await getUserTopFiveOnchainBalance();
      setUpcomingCoins(res?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching upcoming coins:", error);
    }
  };

  const fetchOTCCoins = async () => {
    setIsLoading(true);
    try {
      const res = await getUserTopFiveOTCBalance();
      setOTCCoins(res?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching OTC coins:", error);
      setIsLoading(false);
    }
  };

  const fetchUserCoins = async () => {
    setIsLoading(true);
    try {
      const res = await getUserTopFiveBalance();
      setUserCoins(res?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user coins:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStrigaCoins();
    fetchUpcomingCoins();
    fetchOTCCoins();
    fetchUserCoins();
  }, [refreshInternalBalace]);


  const totalBalance = Number(userCoins?.total_usdt_balance) + Number(upcomingCoins?.total_usdt_balance) + Number(StrigaTable?.totalBalance || 0) + Number(OTCCoins?.total_usdt_balance)

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Stack
        sx={{
          display: { xs: 'flex', lg: 'none' },
          flexDirection: { sm: 'column', md: 'row' },
        }}
      >
        <TotalBalanceValueMobile total={totalBalance} />

      </Stack>

      <Stack
        sx={{
          display: { xs: 'none', sm: 'none', lg: 'flex' },
          flexDirection: { sm: 'column', md: 'row' },
        }}
      >
        <TotalProfit total={totalBalance} />
        <TotalBalanceValue total={totalBalance} />
        <TotalGraphValue total={totalBalance} />
      </Stack>

      <Stack display={"flex"} flexDirection={{ sm: "column", lg: "row" }}>

        {userCoins?.data?.length > 0 && (
          <DashboardCoins
            coinsData={userCoins?.data}
            totalBalance={userCoins?.total_usdt_balance}
            title={"Virtual Wallet"}
            optionColors={optionColors1}

          />
        )}
        {StrigaTable?.topThreeCoins?.length > 0 && (
          <DashboardCoins
            optionColors={optionColors4}
            coinsData={StrigaTable?.topThreeCoins}
            totalBalance={StrigaTable?.totalBalance}
            title={"Bank Wallet"}
          />
        )}{" "}
        {OTCCoins?.data?.length > 0 && (
          <DashboardCoins
            optionColors={optionColors2}
            coinsData={OTCCoins?.data}
            totalBalance={OTCCoins?.total_usdt_balance}
            title={"OTC Wallet"}
          />
        )}
        {upcomingCoins?.data?.length > 0 && (
          <DashboardCoins
            optionColors={optionColors3}
            coinsData={upcomingCoins?.data}
            totalBalance={upcomingCoins?.total_usdt_balance}
            title={"Onchain Wallet"}
          />
        )}

      </Stack>
    </>
  );
};

export default Top5Coins;

const BalanceTitle = styled.h4`
  text-align:left;
  opacity: 0.7;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color:#787985;
  margin-top: 3px;
  margin-left:7px;
  text-transform: uppercase;
`;